import React from "react"
import Layout from "../../components/Layout"
import styled from "styled-components"
import MicroMagImage from "../../components/individualProductPage/images/micromag-20d-pa-360s-view-1.jpg"
import MicroMiniImage from "../../components/individualProductPage/images/micromini-20d-360-view-1.jpg"
import MicroHDImage from "../../components/individualProductPage/images/micro-hd-25c-floor-360s-view-1.jpg"
import MiniHDImage from "../../images/mini-hd-25c_0001_mini-hd-25c-1.6.png"
import MagHDImage from "../../components/individualProductPage/images/mag-hd-29c-360-view-1.jpg"

import PilotImage from "../../images/pilot-29C-1.jpg"
import GTXImage from "../../images/gtx-33c-1.jpg"
import XRImage from "../../images/xr-46c-1.jpg"
import GTRImage from "../../images/gtr-37C-1.jpg"

import Sweeper34Image from "../../components/individualProductPage/images/34-1.jpg"
import TrImage from "../../components/individualProductPage/images/tr-sweeper-1.jpg"

const PageLayout = styled.div`
  max-width: 1400px;
  margin: 0 auto;
`

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 2rem;
`

const DownloadCard = styled.div`
  display: flex;
  border: 2px solid black;
  padding: 1rem;
  margin: 1.5rem;
  .download-link-container {
    display: flex;
    flex-direction: column;
  }

  img {
    width: 150px;
    height: 150px;
  }

  a {
    text-decoration: none;
    color: red;
  }
`

const OperationsManualsPage = () => {
  return (
    <Layout>
      <PageLayout>
        <h1>FactoryCat Operator Manuals</h1>
        <CardsContainer>
          <DownloadCard>
            <img src={MicroMagImage} alt="MicroMag" />
            <div className="content-container">
              <h3>MicroMag</h3>
              <div className="download-link-container">
                <a
                  href="https://www.factorycat.com/docs/manualsOp/FC-MICROMAG-OP-EN.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fas fa-download" /> Brochure
                </a>
                <a
                  href="https://www.factorycat.com/docs/manualsOp/FC-MICROMAG-OP-EN.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fas fa-download" /> Tech Specs
                </a>
                <a
                  href="https://www.factorycat.com/docs/manualsOp/FC-MICROMAG-OP-EN.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fas fa-download" />
                  Operator Manual
                </a>
                <a
                  href="https://www.factorycat.com/docs/manualsOp/FC-MICROMAG-OP-EN.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fas fa-download" />
                  Parts Manual
                </a>
              </div>
            </div>
          </DownloadCard>
          <DownloadCard>
            <img src={MicroMiniImage} alt="MicroMag" />
            <div className="content-container">
              <h3>MicroMini</h3>
              <a
                href="https://www.dropbox.com/s/00ysgqj35i2yoii/MICROMINI-OP-EN.pdf?dl=0"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fas fa-download" /> Download Operator Manual
              </a>
            </div>
          </DownloadCard>
          <DownloadCard>
            <img src={MicroHDImage} alt="MicroMag" />
            <div className="content-container">
              <h3>Micro-HD</h3>
              <a
                href="https://www.dropbox.com/s/mnz7nzrpr9fa9bb/FC-walk-behind-v2-OP-EN.pdf?dl=0"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fas fa-download" /> Download Operator Manual
              </a>
            </div>
          </DownloadCard>
          <DownloadCard>
            <img src={MiniHDImage} alt="MicroMag" />
            <div className="content-container">
              <h3>Mini-HD</h3>
              <a
                href="https://www.dropbox.com/s/mnz7nzrpr9fa9bb/FC-walk-behind-v2-OP-EN.pdf?dl=0"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fas fa-download" /> Download Operator Manual
              </a>
            </div>
          </DownloadCard>
          <DownloadCard>
            <img src={MagHDImage} alt="MicroMag" />
            <div className="content-container">
              <h3>Mag-HD</h3>
              <a
                href="https://www.dropbox.com/s/mnz7nzrpr9fa9bb/FC-walk-behind-v2-OP-EN.pdf?dl=0"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fas fa-download" /> Download Operator Manual
              </a>
            </div>
          </DownloadCard>
          <DownloadCard>
            <img src={PilotImage} alt="MicroMag" />
            <div className="content-container">
              <h3>Pilot</h3>
              <a
                href="https://www.dropbox.com/s/10du8i971h48h7z/FC-RIDERS-V2-OP-EN.pdf?dl=0"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fas fa-download" /> Download Operator Manual
              </a>
            </div>
          </DownloadCard>
          <DownloadCard>
            <img src={GTXImage} alt="MicroMag" />
            <div className="content-container">
              <h3>GTX</h3>
              <a
                href="https://www.factorycat.com/docs/manualsOp/FC-GTX-OP-EN.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fas fa-download" /> Download Operator Manual
              </a>
            </div>
          </DownloadCard>
          <DownloadCard>
            <img src={GTRImage} alt="MicroMag" />
            <div className="content-container">
              <h3>GTR</h3>
              <a
                href="https://www.dropbox.com/s/10du8i971h48h7z/FC-RIDERS-V2-OP-EN.pdf?dl=0"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fas fa-download" /> Download Operator Manual
              </a>
            </div>
          </DownloadCard>
          <DownloadCard>
            <img src={XRImage} alt="MicroMag" />
            <div className="content-container">
              <h3>XR v2.0</h3>
              <a
                href="https://www.factorycat.com/docs/manualsOp/XR-OP-EN.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fas fa-download" /> Download Operator Manual
              </a>
            </div>
          </DownloadCard>
          <DownloadCard>
            <img src={Sweeper34Image} alt="MicroMag" />
            <div className="content-container">
              <h3>34</h3>
              <a
                href="https://www.factorycat.com/docs/manualsOp/FC-34-OP-EN.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fas fa-download" /> Download Operator Manual
              </a>
            </div>
          </DownloadCard>
          <DownloadCard>
            <img src={TrImage} alt="MicroMag" />
            <div className="content-container">
              <h3>TR</h3>
              <a
                href="https://www.factorycat.com/docs/manualsOp/FC-TR-OP-EN.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fas fa-download" /> Download Operator Manual
              </a>
            </div>
          </DownloadCard>
        </CardsContainer>
      </PageLayout>
    </Layout>
  )
}

export default OperationsManualsPage
